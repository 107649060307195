import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  Button,
  ModalHeader,
  Modal,
  ModalBody,
} from 'reactstrap';
import { BsFillPlusSquareFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import PageTitle from '../../common/PageTitle/PageTitle.component';
import {
  StyledPayments, StyledTypes, StyledCardNumber, StyledPaymentsTable,
} from './Payments.styles';
import AddPaymentMethodForm from '../../forms/AddPaymentMethod/AddPaymentMethod.form';
import { GetWorkspaceTypes, UpdateProcessingWorkspace } from '../../../store/actions/Workspaces';
import { GetCards } from '../../../store/actions/Payments';

const FREE_PLAN = 'FREE_PLAN';

function PaymentsView({
  processing,
  GetWorkspaceTypes,
  GetCards,
  UpdateProcessingWorkspace,
  types,
  cards,
}) {
  const { t } = useTranslation();
  const [isAddingPaymentMethodModalVisible, setIsAddingPaymentMethodModalVisible] = useState(false);
  const [isSelectPaymentPlanModalVisible, setIsSelectPaymentPlanModalVisible] = useState(false);

  const handleAddingPaymentMethodModalToggle = () => {
    setIsAddingPaymentMethodModalVisible(!isAddingPaymentMethodModalVisible);
  };

  useEffect(() => {
    GetWorkspaceTypes();
    GetCards();

    loadStripe('pk_live_51LMyJWEDAZ2rF06Jrw2rdUZKjcXoXP8YLju68jvwqqZz7LXF5VcrEz1cHqBRVAEHKkdLwCEiTVhETmOPiCJ1lD4E00A6w8YQPw')
      .then((stripe) => {
        const clientSecret = new URLSearchParams(window.location.search).get(
          'payment_intent_client_secret',
        );

        if (!clientSecret) {
          return;
        }

        stripe.retrievePaymentIntent(clientSecret)
          .then((response) => {
            switch (response.paymentIntent.status) {
              case 'succeeded':
                toast.success(t('Payment succeeded!'));
                break;
              case 'processing':
                toast.warn(t('Your payment is processing.'));
                break;
              case 'requires_payment_method':
                toast.error(t('Your payment was not successful, please try again.'));
                break;
              default:
                toast.error('Something went wrong.');
                break;
            }
          });
      });
  }, []);

  useEffect(() => {
    if (processing.workspaceType.name === FREE_PLAN) {
      setIsSelectPaymentPlanModalVisible(true);
    } else {
      setIsSelectPaymentPlanModalVisible(false);
    }
  }, [processing]);

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <PageTitle title={t('Payments')} />
        </Col>
      </Row>
      <StyledPayments>
        <StyledPaymentsTable>
          <table>
            <tbody>
              {
              (cards || []).filter((card) => card.valid).map((card) => (
                <tr>
                  <td>
                    <StyledCardNumber>
                      •••• •••• ••••
                      {` ${card.cardNumber}`}
                    </StyledCardNumber>
                  </td>
                  <td />
                  <td />
                  <td className="d-flex justify-content-end">
                    <span className="badge-primary">{t('Default')}</span>
                  </td>
                </tr>
              ))
            }
            </tbody>
          </table>
        </StyledPaymentsTable>

        <Row>
          <Col xs={12} className="d-flex justify-content-center">
            <Button
              className="add-payment-method mt-4"
              type="primary"
              outline
              size="lg"
              onClick={handleAddingPaymentMethodModalToggle}
            >
              <BsFillPlusSquareFill />
              {t('Add payment method')}
            </Button>
          </Col>
        </Row>
      </StyledPayments>

      <Modal
        isOpen={isAddingPaymentMethodModalVisible}
        toggle={handleAddingPaymentMethodModalToggle}
      >
        <ModalHeader
          toggle={handleAddingPaymentMethodModalToggle}
        >
          {t('Add payment method')}
        </ModalHeader>
        <AddPaymentMethodForm
          onSuccess={() => setIsAddingPaymentMethodModalVisible(false)}
        />
      </Modal>

      <Modal
        isOpen={isSelectPaymentPlanModalVisible}
        size="lg"
      >
        <ModalHeader>
          {t('Select payment plan')}
        </ModalHeader>
        <ModalBody>
          <StyledTypes>
            <table>
              <thead>
                <th>{t('Name')}</th>
                <th>{t('Users')}</th>
                <th>{t('Price per user')}</th>
                <th>{t('Total price')}</th>
                <th> </th>
              </thead>

              <tbody>
                {
                (types || []).filter((type) => type.name !== 'FREE_PLAN').map((type) => (
                  <tr>
                    <td>{type.name}</td>
                    <td>{type.usersLimit}</td>
                    <td>{(type.amount / type.usersLimit).toFixed(2)}</td>
                    <td>{type.amount}</td>
                    <td className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          UpdateProcessingWorkspace({
                            name: type.name,
                            id: type.id,
                          });
                        }}
                        type="button"
                        color="secondary"
                        outline
                      >
                        {t('Select')}
                      </Button>
                    </td>
                  </tr>
                ))
              }
              </tbody>
            </table>
          </StyledTypes>

        </ModalBody>
      </Modal>

    </Container>
  );
}

PaymentsView.propTypes = {
  GetWorkspaceTypes: PropTypes.func.isRequired,
  UpdateProcessingWorkspace: PropTypes.func.isRequired,
  GetCards: PropTypes.func.isRequired,
  processing: PropTypes.object.isRequired,
  types: PropTypes.array.isRequired,
  cards: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  processing: state.Workspaces.processing,
  types: state.Workspaces.types.data,
  cards: state.Payments.cards.data,
});

const mapDispatchToProps = (dispatch) => ({
  GetWorkspaceTypes: () => dispatch(GetWorkspaceTypes()),
  UpdateProcessingWorkspace: (data) => dispatch(UpdateProcessingWorkspace(data)),
  GetCards: () => dispatch(GetCards()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentsView);
